<template>
  <div class="vg_wrapper" v-loading="loadingFlag">
    <el-row :gutter="24" v-if="dashRequPush9004Flag">
      <el-col :md="24" style="margin-bottom: 20px">
        <DashRequPush9004></DashRequPush9004>
      </el-col>
    </el-row>
    <el-row :gutter="24" v-if="mode === 0">
      <el-col :md="!boardPushFlag ? 12 : 10">
        <!--        <board-left></board-left>-->
        <boardLeftRequReceive></boardLeftRequReceive>
      </el-col>
      <el-col :md="!boardPushFlag ? 12 : 10">
        <board-right></board-right>
      </el-col>
      <el-col :md="!boardPushFlag ? 0 : 4">
        <board-push v-if="boardPushFlag" @pushHide="pushHide"></board-push>
      </el-col>
    </el-row>
    <div v-else class="proc_card">
      <el-row>
        <el-col>
          <p class="titleName">
            员工姓名:<span>{{ userName }}</span>
          </p>
        </el-col>
      </el-row>
      <div class="empr_outer" v-if="procList.length === 0">
        <div class="empt"><i class="el-icon-warning-outline"></i> <span>此账号暂无工序</span></div>
      </div>
      <div v-else :class="{ btnBox: procList.length !== 1, btnBoxO: procList.length === 1 }">
        <div class="btn" v-if="procList.indexOf('insp') !== -1" @click="btnClick('insp')">
          <img src="@/assets/image/index_icon/insp.png" alt="" />
          <span>检验</span>
        </div>
        <div class="btn" v-if="procList.indexOf('embr') !== -1" @click="btnClick('embr')">
          <img src="@/assets/image/index_icon/embr.png" alt="" />
          <span>电绣</span>
        </div>
        <div class="btn" v-if="procList.indexOf('emcd') !== -1" @click="btnClick('emcd')">
          <img src="@/assets/image/index_icon/emcd.png" alt="" />
          <span>制卡</span>
        </div>
        <div class="btn" v-if="procList.indexOf('lase') !== -1" @click="btnClick('lase')">
          <img src="@/assets/image/index_icon/lase.png" alt="" />
          <span>激光</span>
        </div>
        <div class="btn" v-if="procList.indexOf('manu') !== -1" @click="btnClick('manu')">
          <img src="@/assets/image/index_icon/manu.png" alt="" />
          <span>手工</span>
        </div>
        <div class="btn" v-if="procList.indexOf('mase') !== -1" @click="btnClick('mase')">
          <img src="@/assets/image/index_icon/mase.png" alt="" />
          <span>机缝</span>
        </div>
        <div class="btn" v-if="procList.indexOf('open') !== -1" @click="btnClick('open')">
          <img src="@/assets/image/index_icon/open.png" alt="" />
          <span>开版</span>
        </div>
        <div class="btn" v-if="procList.indexOf('stam') !== -1" @click="btnClick('stam')">
          <img src="@/assets/image/index_icon/stam.png" alt="" />
          <span>印花</span>
        </div>
        <div class="btn" v-if="procList.indexOf('stpr') !== -1" @click="btnClick('stpr')">
          <img src="@/assets/image/index_icon/stpr.png" alt="" />
          <span>备料</span>
        </div>
        <div class="btn" v-if="procList.indexOf('comp') !== -1" @click="btnClick('comp')">
          <img src="@/assets/image/index_icon/comp.png" alt="" />
          <span>复合</span>
        </div>
      </div>
      <!-- 
      <el-button class="btn" icon="el-icon-edit-outline" v-for="(item,index) in procList" :key="index" @click="btnClick(item.value)">{{item.label}}</el-button>
       -->
    </div>

    <div style="width: 240px"></div>
  </div>
</template>

<script>
import { get } from '@api/request';
import { userAPI } from '@api/modules/user';
import { procAPI } from '@api/modules/proc';
import { optnAPI } from '@api/modules/optn';
import boardLeftRequReceive from '@/views/component/boardCommon/boardLeftRequReceive';
import boardRight from '@/views/component/boardCommon/boardRight';
import boardPush from '@/views/component/boardCommon/boardPush';
import DashRequPush9004 from '@/views/component/boardCommon/DashRequPush9004';

export default {
  name: 'Home',
  components: {
    boardPush,
    boardLeftRequReceive,
    boardRight,
    DashRequPush9004
  },
  data() {
    return {
      mode: null,
      procList: [],
      pushList: [],
      userName: '',
      boardLeftFlag: true,
      boardRightFlag: true,
      boardPushFlag: true,
      loadingFlag: true,
      dashRequPush9004Flag: false
    };
  },
  created() {
    this.initData();
    setTimeout(() => {
      this.loadingFlag = false;
    }, 1000);
    this.pushList = JSON.parse(this.$cookies.get('push'));
    let find = this.pushList.find(x => x.perm_id === 9004);
    if (find) {
      this.dashRequPush9004Flag = true;
    }
  },
  methods: {
    initData() {
      this.isLogin();
      this.getUserMode();
      this.getProcForm();
      this.getDepartment();
    },
    pushHide() {
      this.boardPushFlag = false;
    },
    isLogin() {
      get(userAPI.logUserId)
        .then(res => {
          // if(res.data.data){
          //   console.log('登录状态。。。',res.data.data)
          // } else {
          //   this.jump(`/login`)
          // }
        })
        .catch(() => {
        });
    },
    getUserMode() {
      this.mode = this.$cookies.get('userMode').type;
      this.userName = this.$cookies.get('userInfo').stff_name;
    },
    getProcForm() {
      if (this.mode == 1) {
        get(procAPI.get_user_proc)
          .then(res => {
            if (res.data.code !== 0) {
              console.log('此账号暂无工序');
              return;
            }
            this.procList = res.data.data.form.proc_codes.split(',');
            var codes = res.data.data.form.proc_codes.split(',');
          })
          .catch(res => {
          });
      }
    },
    btnClick(val) {
      this.jump('read_list', { proc: val });
      this.$EventBus.$emit('openRead');
    },
    //获取打样部门
    getDepartment() {
      get(optnAPI.getOptnByPermId, { perm_id: 10009 })
        .then(res => {
          if (res.data.code === 0) {
            let userInfo = this.$cookies.get('userInfo');
            let flag = res.data.data.form.optn_cntt_list.some(item => {
              return item.param2 == userInfo.dept_id;
            });
            if (flag) {
              userInfo.isDYJ = true; //为true 就是打样间的员工
              this.$cookies.set('userInfo', userInfo, 60 * 60 * 24 * 30);
            } else {
              userInfo.isDYJ = false; //为true 就是打样间的员工
              this.$cookies.set('userInfo', userInfo, 60 * 60 * 24 * 30);
            }
            return;
          } else if (res.data.code === 5) {
            return;
          }
          this.$message.error(res.data.msg);
        })
        .catch(res => {
          this.$message.error(res.data.msg);
        });
    }
  }
};
</script>

<style scoped lang="scss">
.vd_card_button {
  padding: 2px 0px;
}

.btn {
  width: 47%;
  margin: 0;
  font-size: 40px;
  margin-left: 10px;
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
  border: 1px solid #cccccc;
  background-color: #ececec;
  line-height: 240px;
}

.btn img {
  vertical-align: middle;
  margin-right: 15px;
}

.btnBox {
  display: flex;
  flex-wrap: wrap;
  margin-left: 15px;
  margin-top: 10px;
  // justify-content: space-around;
}

.btnBoxO {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.titleName {
  font-size: 28px;

  span {
    font-weight: 700;
    font-size: 32px;
  }
}

.proc_card {
  height: 100%;
}

.empr_outer {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empt {
  text-align: center;
  font-size: 34px;
  border: 1px dotted;
  line-height: 68px;
  width: 40%;
}
</style>
